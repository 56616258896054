.error-page {
    color: $gray-700;
    height: 100vh;

    .wrapper {
        background: url(../static/images/bg/bg_error.svg) no-repeat top right / auto 100%;
    }

    .error-intro {
        font-size: $font-size-error;
    }
    .error-title {
        font-size: $font-size-error-h1;
        line-height: 1.5;
    }

    @include media-breakpoint-down(md) {
        .error-intro {
            font-size: calc(#{$font-size-error}/2);
        }
        .error-title {
            font-size: $h1-font-size;
        }
    }
}