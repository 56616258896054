[class^="fo-"]:before, [class*=" fo-"]:before {
    font-family: "font-onem";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    // width: 1em;
    text-align: center;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}



$fo-star: \e800;
$fo-aide: \e801;
$fo-back_top_icon: \e802;
$fo-burger_menu: \e803;
$fo-calendar: \e804;
$fo-call: \e805;
$fo-chatbot: \e806;
$fo-contact: \e807;
$fo-filter: \e808;
$fo-fleche: \e809;
$fo-fleche_hover: \e80a;
$fo-user: \e80b;
$fo-pdf_1: \e80c;
$fo-pdf_2: \e80d;
$fo-search: \e80e;
$fo-word_1: \e80f;
$fo-icon_cart: \e810;
$fo-word_2: \e811;

.fo-star:before { content: fo-content($fo-star); } /* '' */
.fo-aide:before { content: fo-content($fo-aide); } /* '' */
.fo-back_top_icon:before { content: fo-content($fo-back_top_icon); } /* '' */
.fo-burger_menu:before { content: fo-content($fo-burger_menu); } /* '' */
.fo-calendar:before { content: fo-content($fo-calendar); } /* '' */
.fo-call:before { content: fo-content($fo-call); } /* '' */
.fo-chatbot:before { content: fo-content($fo-chatbot); } /* '' */
.fo-contact:before { content: fo-content($fo-contact); } /* '' */
.fo-filter:before { content: fo-content($fo-filter); } /* '' */
.fo-fleche:before {
    content: fo-content($fo-fleche);
    vertical-align: middle;
} /* '' */
.fo-fleche_hover:before { content: fo-content($fo-fleche_hover); } /* '' */
.fo-user:before { content: fo-content($fo-user); } /* '' */
.fo-pdf_1:before { content: fo-content($fo-pdf_1); } /* '' */
.fo-pdf_2:before { content: fo-content($fo-pdf_2); } /* '' */
.fo-search:before { content: fo-content($fo-search); } /* '' */
.fo-word_1:before { content: fo-content($fo-word_1); } /* '' */
.fo-icon_cart:before {
    content: fo-content($fo-icon_cart);
    font-size: 1.1rem;
    vertical-align: middle;
    margin-bottom: 4px;
} /* '' */
.fo-word_2:before { content: fo-content($fo-word_2); } /* '' */
