.font-weight-semi {
    font-weight: 600 !important;
}

.text-xs {
    font-size: $font-size-xs !important;
}
.text-smm {
    font-size: $font-size-smm !important;
}
.text-sm {
    font-size: $font-size-sm !important;
}
.text-base {
    font-size: $font-size-base !important;
}
.text-lg {
    font-size: $font-size-lg !important;
}
.text-big {
    font-size: $font-size-big !important;
}
.text-xl {
    font-size: $font-size-xl !important;
}
.text-xxl {
    font-size: $font-size-xxl !important;
}

.text-underline {
    text-decoration: underline !important;
}

.text-decoration-hover {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.font-family-primary {
    font-family: $font-family-primary;
}

.font-family-secondary {
    font-family: $font-family-secondary;
}

.text-purple {
  color: $purple !important;
}

@each $code, $color in $grays {
  .text-gray-#{$code} {
    color: $color !important;
  }
}
