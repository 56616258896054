.accordion {
    background-color: $light;
    padding: 0.25rem;

    .accordion-title {
        font-size: $font-size-base;
        font-weight: 400 !important;
        padding: 1rem 3rem 1rem 1.625rem;
        margin-bottom: 0 !important;
        margin-top: 0 !important;

        a {
            color: $body-color;
            position: relative;
            display: block;
            text-decoration: none !important;

            &:after {
                color: $primary;
            }

            &[aria-expanded] {
                &:after {
                    @include faw-icon(\f078, 14);
                    position: absolute;
                    top: 50%;
                    right: -1.5rem;
                    transform: translateY(-50%);
                }
            }
    
            &[aria-expanded="true"] {
                &:after {
                    @include faw-icon(\f077, 14);
                }
            }
    
            &[aria-expanded="false"] {
                border-bottom-right-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
                transition: 0.1s ease 0.3s;
            }
        }
    }

    .accordion-collapse-inner {
        padding: 1rem;
        background-color: $white;
    }

    .collapse {
        &:not(.show) + .accordion-title {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 1.625rem;
                right: 1.625rem;
                height: 1px;
                background-color: $border-color;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .accordion-title {
            font-size: $font-size-xl;
        }
    }
}