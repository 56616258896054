.modal, #footer {
    .form-control {
        border-left: 0;
        border-top: 0;
        border-right: 0;
        padding-left: 0;
        border-radius: 0;
    }

    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-append > .btn {
        border-radius: 0;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 1px solid $input-border-color;
        padding-left: 0;
        padding-right: 0;
    }
}

.form-group {
    &.disabled {
        opacity: 0.25;

        label, .custom-select {
            pointer-events: none;
        }
    }
  &.required > label:after, &.required > legend:after  {
      content:" *";
      color:red;
    }
}

.ems-form-custom {
    form {
        padding: 1.5rem;
        background-color: $light;
        border: 1px solid $border-color;
        border-radius: $border-radius;
    }

    .form-group {
        margin-bottom: rem(40);
    }

    .form-control {
        background-color: $white;
        padding: 0.5rem 1.5rem 0.5rem 1rem;
    }

    label {
        display: block;
    }

    hr {
        margin-top: rem(40);
        margin-bottom: rem(40);
    }

    textarea {
        height: rem(175);
    }

    .custom-file-label {
        background-color: $white;

        &:after {
            background-color: $white;
            color: $primary;
        }
    }

    select {
      -webkit-appearance: none;
      background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23EB142A' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1.125rem center/8px 10px no-repeat;
    }
}
