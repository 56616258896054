.heading-image {
    height: 270px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.content-body {
    padding-top: rem(20);
    padding-bottom: rem(20);

    h1 {
        margin-bottom: rem(50);
    }
}

.chapo {
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
    font-size: rem(22);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 10px;
        background-image: linear-gradient(to bottom, $primary, $pink);
    }

    ul, ol {
        padding-left: 1.2rem;
        font-family: "Source Sans Pro";

        li:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }

    .card-footer {
        padding-top: 0;
    }

    a {
        text-decoration: underline;
    }
}

.content-wysiwyg {
    ul, ol {
        &:not(.pagination):not(.list-unstyled) {
            list-style: none;
            padding-left: 1.2rem;
            font-family: "Source Sans Pro";

            li {
                position: relative;
                margin-bottom: 1rem;

                &:before {
                    color: $primary;
                    display: block;
                    width: 1rem;
                    left: -1.2rem;
                    position: absolute;
                }
            }

            ul {
                margin-top: 1rem;

                li {
                    margin-bottom: rem(10);

                    &:before {
                        content: "\00BA";
                        font-size: 1rem;
                        top: 0.2rem;
                    }
                }
            }
        }
    }

    ul:not(.pagination):not(.list-unstyled) {
        li {
            &:before {
                content: "\2022";
                font-size: 1.75rem;
                top: -0.7rem;
            }
        }
    }

    ol:not(.pagination):not(.list-unstyled) {
        padding-left: 0.6rem;
        counter-reset: li;

        li {
            padding-left: 1rem;

            &:before {
                counter-increment: li;
                content: counters(li, ".") ". ";
                font-size: 1rem;
                top: 0;
                width: 2.5rem;
                display: inline-block;
                text-align: right;
                left: -2.2rem;
                position: absolute;
            }

            ol {
                padding-left: 1.2rem;
                margin-top: 0.4rem;
            }
        }
    }

    a:not(.btn):not(.page-link):not([data-toggle="collapse"]):not(.text-decoration-hover) {
        text-decoration: underline;
    }
}

.formulaire-item {
    display: flex;
    align-items: center;
    background-color: $light;
    padding: 0.5rem 1.5rem;
    position: relative;
    font-family: $font-family-secondary;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: $primary;
        width: 2px;
    }

    .formulaire-item-label {
        display: inline-block;
        text-decoration: none !important;
        color: $body-color;
        padding-right: 1rem;

        &:hover {
            text-decoration: underline !important;
        }
    }

    > a {
        color: darken($primary, 3%);
    }

    a {
        i {
            font-size: 2rem;
            color: $primary !important;
        }

        & + a {
            margin-left: 1rem;
        }
    }

    & + .formulaire-item {
        border-top: 1px solid $border-color;
    }

    .formulaire-documents {
        min-width: 60px;
        text-align: right;
    }
}

.select-toc + h2 {
    margin-top: 0 !important;
}

[data-toggle="collapse"] {
    &[aria-expanded="false"] {
        .fa-chevron-up {
            display: none;
        }
    }

    &[aria-expanded="true"] {
        .fa-chevron-down {
            display: none;
        }
    }
}
