@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 rgb(235 20 42 / 50%)
  }

  to {
    box-shadow: 0 0 0 0.75rem rgb(235 20 42 / 0%)
  }
}

.btn {
  font-weight: 500;
  font-family: $font-family-primary;

  &.btn-primary {
    transition: 0.6s ease;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, $pink, $red 50%);

    &:hover {
      background-position: 100% 0;
      border-color: $primary;
    }
  }

  &.btn-outline-white {
    color: $white;
    border-color: $white;

    &:hover {
      color: $primary;
      background-color: $white;
    }
  }

  &.btn-outline-primary {
    color: $primary !important;
    border-color: $primary;
    background-color: $white;

    &:hover {
      color: $white !important;
      background-color: $primary;
    }
  }

  &.btn-outline-purple {
    color: $purple !important;
    border-color: $purple;
    background-color: $white;

    &:hover {
      color: $white !important;
      background-color: $purple;
    }
  }

  &.pulse {
    animation-name: pulse;
    animation-duration: 0.3s;
  }

  &.btn-sm {
    padding: 0.25rem 1rem;
    border-radius: 1rem;
  }
}

.btn-round {
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 50%;
  padding: 0;

  &.btn-lg {
    width: 54px;
    height: 54px;
    line-height: 54px;
  }
}

.btn-download {
  font-size: $font-size-xxl;
  color: $primary !important;
  background-color: $light;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  width: 54px;
  height: 54px;
  position: relative;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include media-breakpoint-up(md) {
    background-color: transparent;
  }
}

// fix safari
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}
