.back-to-top {
    position: fixed;
    bottom: 1.25rem;
    right: 1.25rem;
    z-index: $zindex-sticky;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: $transition-base;

    @include media-breakpoint-up(lg) {
        &.active {
            visibility: visible;
            opacity: 1;
            pointer-events: initial;
        }
    }
}
