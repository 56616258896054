.custom-select {
  border-color: $purple-lighter;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($purple, 0.25);
  }
}

.ems-form-custom {
  form {
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 1.3125rem;
  }

  .form-group {
    margin-bottom: 1rem;

    &:first-child {
      h2 {
        margin-top: 0;
      }
    }
  }

  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
}
