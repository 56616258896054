// Links
a {
    &[target="_blank"]:not(.btn):not(.no-icon),
    &[target="_new"]:not(.btn):not(.no-icon) {
        &:after{
            margin-left:$space-icon-link;
            @include faw-icon(\f35d);
            font-size: 80%;
            vertical-align: 1px;
        }
        @each $doc, $value in $icon-link {
            @include icon-link($doc, $value);
        }
    }
}

.chapo, .content-wysiwyg {
  a {
    &:not(.btn):not(.no-icon) {
      @each $doc, $value in $icon-link {
          @include icon-link($doc, $value);

          &:after {
            margin-left:$space-icon-link;
          }
      }
    }
  }
}

.formulaire-item {
  a {
    @each $doc, $value in $icon-link {
        &:after {
          content: none !important;
        }
    }
  }
}

p {
    font-family: "Source Sans Pro";
}

.main-content {
    h2, .h2 {
        margin-bottom: rem(30);
        line-height: 1.5;

        &:not(:first-child) {
            margin-top: rem(60);
        }
    }

    h3, .h3 {
        margin-bottom: rem(25);

        &:not(:first-child) {
            margin-top: rem(35);
        }
    }

    h4, .h4 {
        margin-bottom: rem(20);
        font-weight: bold;

        &:not(:first-child) {
            margin-top: rem(30);
        }
    }

    h5, .h5 {
        margin-bottom: rem(20);

        &:not(:first-child) {
            margin-top: rem(25);
        }
    }

    h6, .h6 {
        margin-bottom: rem(20);

        &:not(:first-child) {
            margin-top: rem(25);
        }
    }

    @include media-breakpoint-down(sm) {
        h2, .h2 {
            margin-bottom: 1.5rem;

            &:not(:first-child) {
                margin-top: 2rem;
            }
        }

        h3, .h3 {
            margin-bottom: 1.375rem;

            &:not(:first-child) {
                margin-top: 1.75rem;
            }
        }

        h4, .h4 {
            margin-bottom: 1.125rem;

            &:not(:first-child) {
                margin-top: 1.5rem;
            }
        }

        h5, .h5 {
            margin-bottom: 1rem;

            &:not(:first-child) {
                margin-top: 1.25rem;
            }
        }

        h6, .h6 {
            margin-bottom: 1rem;

            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}



@include media-breakpoint-down(sm) {
    h1, .h1 {
        font-size: 1.6rem;
    }

    h2, .h2 {
        font-size: 1.5rem;
    }

    h3, .h3 {
        font-size: 1.375rem;
    }

    h4, .h4 {
        font-size: 1.25rem;
        font-weight: 500;
    }

    h5, .h5 {
        font-size: 1.125rem;
    }

    h6, .h6 {
        font-size: 1rem;
    }
}

body.cke_editable {
    margin: 5px;
}

body.cke_editable, .wysiwyg-preview {
    ul, ol {
        font-family: "Source Sans Pro";

        &:not(.pagination) {
            list-style: none;
            padding-left: 1.2rem;
            font-family: "Source Sans Pro";

            li {
                position: relative;
                margin-bottom: 1rem;

                &:before {
                    color: $primary;
                    display: block;
                    width: 1rem;
                    left: -1.2rem;
                    position: absolute;
                }
            }

            ul {
                margin-top: 1rem;

                li {
                    margin-bottom: rem(10);

                    &:before {
                        content: "\00BA";
                        font-size: 1rem;
                        top: 0.2rem;
                    }
                }
            }
        }
    }

    ul:not(.pagination) {
        li {
            &:before {
                content: "\2022";
                font-size: 1.75rem;
                top: -0.7rem;
            }
        }
    }

    ol:not(.pagination) {
        padding-left: 0.6rem;
        counter-reset: li;

        li {
            padding-left: 1rem;

            &:before {
                counter-increment: li;
                content: counters(li, ".") ". ";
                font-size: 1rem;
                top: 0;
                width: 2.5rem;
                display: inline-block;
                text-align: right;
                left: -2.2rem;
                position: absolute;
            }

            ol {
                padding-left: 1.2rem;
                margin-top: 0.4rem;
            }
        }
    }

    .table {
        thead {
            th {
                border-width: 0;
            }
        }
        tbody {
            th {
                font-weight: 500;

                &[scope="row"] {
                    font-family: $font-family-secondary;
                    font-weight: 600;
                }

                &[scope="col"] {
                    font-size: rem(14);
                }
            }

            td {
                vertical-align: middle;
                font-family: $font-family-secondary;
            }
        }
    }
}

#skip-nav {
    &:focus, &:active {
        color: #fff;
        background-color:#000;
        left: auto;
        top: auto;
        width: 30%;
        height: auto;
        overflow:auto;
        margin: 10px 35%;
        padding:5px;
        border-radius: 15px;
        border:4px solid yellow;
        text-align:center;
        font-size:1.2em;
        z-index:9999;
        clip: unset;
    }
}
