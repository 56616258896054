$toggler-thickness: .15rem;
$nav-toggler-color: $body-color;

.navbar-toggler {
  border:0;
  height:rem(30);
  padding:0;
  position:relative;
  transition:.25s ease-in-out;
  width:rem(30);
  *{
    transition:.25s ease-in-out;  
  }
  span {
    display:block;
    background: $nav-toggler-color;
    border-radius:2px;
  }
  .hamburger {
    padding-top: .25rem;
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background: none !important;
    span {
      width:rem(30);
      height:$toggler-thickness;
      position:relative;
      margin:rem(7) 0;
      &:nth-child(1){
        transition-delay:.5s;
        margin-top:0;
      }
      &:nth-child(2){
        transition-delay:.625s;
      }
      &:nth-child(3){
        transition-delay:.75s;
        margin-bottom:0;
      }
    }
  }
  .cross {
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    transform:rotate(45deg);
    background: none !important;
    span{
      &:nth-child(1){
        height:0%;
        width:$toggler-thickness;
        position:absolute;
        top:10%;
        left: calc(50% - #{$toggler-thickness}/2);
        transition-delay:0s;
      }
      &:nth-child(2){
        width:0%;
        height:$toggler-thickness;
        position:absolute;
        left:10%;
        top: calc(50% - #{$toggler-thickness}/2);
        transition-delay:.25s;
      }
    }
  }
  &[aria-expanded="true"] {
    .hamburger {
      span {
        width:0%;
        &:nth-child(1){
          transition-delay:0s;
        }
        &:nth-child(2){
          transition-delay:.125s;
        }
        &:nth-child(3){
          transition-delay:.25s;
        }
      }
    }
    .cross {
      span {
        background: $nav-toggler-color;
        &:nth-child(1){
          height:80%;
          transition-delay:.625s;
        }
      }
      span {
        &:nth-child(2){
          width:80%;
          transition-delay:.375s;
        }
      }    
    }
  }
}