.fleche-container {
    overflow: hidden;

    &.fixed-width {
        width: 13px;
        min-width: 13px;
    }

    .fo-fleche {
        margin-left: -2px;
        transition: $transition-base;
        vertical-align: middle;
        margin-bottom: 2px;
        display: inline-block;
    }
}

a:hover {
    .fo-fleche {
        margin-left: 0;
    }
}