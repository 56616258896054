a:has(.advantage-pill) {
  .advantage-pill {
    position: relative;
    display: block;
    width: rem(80);
    height: rem(80);
    top: 0;
    left: 0;
    background-image: $background-primary;
    border-radius: 50%;

    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    .advantage-pill {
      background: $purple;

      > img {
        display: none;
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 2rem;
        @include font-jobs-icons(\e908, 32);
      }
    }
  }
}

.jobs-icon-content {
  margin-bottom: map-get($spacers, 5);
  display: grid;
  grid-template-columns: calc(80px + 2rem) 1fr;

  .icon {
    width: rem(80);
    height: rem(80);
    line-height: rem(80);
    background-image: $background-primary;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    grid-row: 1 / span 2;
  }

  .title {
    margin-bottom: 0;
    margin-top: 0;
  }

  .content {
    margin-top: 1rem;
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: calc(80px + 1rem) 1fr;

    .icon {
      grid-row: 1 / 1;
    }

    .title {
      align-self: center;
    }

    .content {
      grid-column: 1 / span 2;
    }
  }
}
