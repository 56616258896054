.swiper-jobs {
  --swiper-navigation-size: 1rem;
  --swiper-navigation-color: #{$purple};
  --swiper-pagination-color: #{$purple};
  --swiper-pagination-bullet-inactive-color: #{$purple-light};
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-size: 9px;

  .swiper-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .swiper-button-prev, .swiper-button-next, .swiper-pagination {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    margin-top: 0;
  }

  .swiper-pagination {
    width: auto;
  }

  .swiper-button-prev, .swiper-button-next {
    width: rem(40);
    height: rem(40);
  }

  .swiper-button-prev::after {
    @include faw-icon("\f053", 24);
  }
  .swiper-button-next::after {
    @include faw-icon("\f054", 24);
  }
}
