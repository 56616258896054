#header {
    background-color: white;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    z-index: $zindex-fixed;
    
    .header-top {
        border-bottom: 1px solid $gray-300;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .navbar-nav, [data-target="#searchModal"] {
        font-weight: $font-weight-light;

        @include media-breakpoint-up(xl) {
            font-size: rem(13);
        }
    }

    [data-target="#searchModal"] {
        padding-top: 0.5rem;
        padding-bottom: 8.5px;
    }

    .navbar-nav {
        &:not(.social-nav) {
            .nav-item {
                position: relative;

                .nav-link {
                    padding-bottom: 8.5px;
                    position: relative;
                    display: inline-block;
    
                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 3px;
                        background-color: $primary;
                        height: 2px;
                        width: 0;
                        transition: $transition-base;
                    }
                }

                &.active, &:hover {
                    .nav-link {
                        &:after {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .social-nav, .lang-nav {
        .nav-item {
            margin-right: $nav-link-padding-x * 1.5;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .navbar-brand {
        font-weight: 500;

        span {
            display: inline-block;
            white-space: initial;
            font-size: 1rem;
            line-height: 1.2;
        }
    }

    .navbar-collapse {
        .scrollable {
            // use settings from .navbar-collapse
            display: inherit;
            flex-basis: inherit;
            justify-content: inherit;
            flex-grow: inherit;
            align-items: inherit;
        }
    }

    .btn.dropdown-toggle {
        padding-right: 0;
        border-radius: 0;
        font-weight: 600;
        text-transform: uppercase;
        font-size: rem(12);

        &:after {
            border: 0;
            color: $primary;
        }

        &[aria-expanded] {
            &:after {
                @include faw-icon(\f078);
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }

        &[aria-expanded="true"] {
            &:after {
                @include faw-icon(\f077);
            }
        }
    }

    .dropdown-menu {
        border-radius: rem(10);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 0;
        text-transform: uppercase;
        // margin: 0.6rem 0 0;
        box-shadow: 0px 5px 6px -2px rgba(0, 0, 0, 0.2);

        .dropdown-item {
            line-height: 2;
            font-size: $font-size-smm;
            font-weight: 600;
            color: $body-color;

            span {
                display: inline-block;
                position: relative;
            
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $primary;
                    height: 2px;
                    transition: $transition-base;
                    width: 0;
                }
            }

            &.active, &:hover, &:focus {
                background-color: initial;

                span {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .navbar-collapse {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            padding: 1rem;
            height: 100vh !important;
            background-color: $light;

            .scrollable {
                overflow-y: auto;
            }

            .target-nav, .second-nav, .dropdown-menu {
                padding-bottom: 1.5rem;
                margin-bottom: 1.5rem;
                position: relative;
    
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $gray-300;
                    height: 1px;
                }
            }
        }

        .btn-dossier {
            padding: 0.5rem;
            border-radius: 50%;
            width: 42px;
            height: 42px;
            position: relative;

            > * {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        
        .btn.dropdown-toggle {
            display: none;
        }

        .dropdown-menu {
            position: relative;
            top: auto;
            left: auto;
            float: none;
            background: transparent;
            box-shadow: none;
            margin-top: 0;
            padding: 0;
            display: block !important;

            .dropdown-item {
                padding: 0.5rem 0 8.5px;
                line-height: 1.5;
                font-size: 0.8125rem;
                white-space: normal;

                span {
                    &:after {
                        bottom: -3px;
                    }
                }
            }
        }

        .my-file-static {
            display: none;
        }

        .navbar-brand-static {
            display: none;
        }
    }

    @include media-breakpoint-up(xl) {
        transition: 0.2s ease;

        .navbar-brand-mobile {
            display: none;
        }

        .my-file-tiny {
            display: none;
        }

        [data-target="#searchModal"] {
            position: relative;
                
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 3px;
                background-color: $primary;
                height: 2px;
                width: 0;
                transition: $transition-base;
            }

            &.active, &:hover {
                &:after {
                    width: 100%;
                }
            }
        }

        .navbar-nav:not(.social-nav) {
            .nav-item {
                margin-right: $nav-link-padding-x * 1.5;
    
                &:last-child {
                    margin-right: 0;
                }
    
                .nav-link {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .lang-nav + .dropdown {
            margin-left: 1.5rem;
            padding-left: 1.25rem;
            position: relative;
            font-size: $font-size-smm;

            .dropdown-toggle {
                position: relative;
            }
    
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0.45rem;
                bottom: 0.45rem;
                background-color: $gray-300;
                width: 1px;
            }
        }

        .second-nav {
            margin-left: 1.25rem;
            padding-left: 1.25rem;
            position: relative;
    
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0.4rem;
                bottom: 0.4rem;
                background-color: $gray-300;
                width: 1px;
            }
        }

        .lang-nav, .target-nav {
            .nav-link {
                font-size: $font-size-smm;
            }
        }

        .target-nav {
            flex-direction: row;
        }
    }
}

.header-tiny {
    #header {
        top: -73px;

        .navbar-brand-mobile {
            display: flex;
        }

        .my-file-tiny {
            display: block;
        }

        .my-file-static {
            display: none;
        }

        .navbar-brand-static {
            display: none;
        }
    }
}

[lang="fr"] {
    .navbar-brand {
        span {
            max-width: 130px;
        }
    }
}

[lang="nl"] {
    .navbar-brand {
        span {
            max-width: 160px;
        }
    }
}

[lang="de"] {
    .navbar-brand {
        span {
            max-width: 170px;
        }
    }
}