.table {
    thead {
        th {
            border-width: 0;
        }
    }
    tbody {
        th {
            font-weight: 500;

            &[scope="row"] {
                font-family: $font-family-secondary;
                font-weight: 600;
            }

            &[scope="col"] {
                font-size: rem(14);
            }
        }

        td {
            vertical-align: middle;
            font-family: $font-family-secondary;
        }
    }

    &.datatable {
        border-collapse: collapse !important;
        margin-top: 0 !important;
        font-size: rem(14);

        thead {
            background-color: $light;
        }

        .fo {
            font-size: rem(40);
        }
    }

    &.table-brochure {
        th {
            width: 35%;
        }
    }
}

.table_wrapper {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 1rem;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination{
    justify-content: center;
}

@include media-breakpoint-down(xs) {
    .page-datatable {
        .table {
            th, td {
                border-top: 0;
                border-bottom: 1px solid $border-color;
                padding-left: 0;
                padding-right: 0;
            }

            th {
                font-family: $font-family-primary;
            }

            td {
                font-family: $font-family-secondary;
                text-align: right;
            }
        }
    }
}

caption {
    caption-side: top;
}