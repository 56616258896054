.help {
    width: 290px;
    position: fixed;
    bottom: 1.5rem;
    right: 0;
    z-index: $zindex-sticky;

    .help-content {
        background-image: linear-gradient(to bottom, $red, $pink);
        padding: 1.5rem;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;

        opacity: 0;
        pointer-events: none;
        z-index: 1;
        display: none;
    }

    p {
        color: $white;
    }

    .btn-helper {
        padding: 0.25rem 2rem 0.25rem 0.25rem;
        line-height: 1;
        display: flex;
        align-items: center;
        width: 130px;
        position: absolute;
        bottom: 2.5rem;
        right: -1rem;
        z-index: -1;
        & + .btn-helper {
            bottom: 0;
        }

        .fo {
            font-size: 1rem;
            margin-right: .5rem;
            padding: 4px;
            border: 2px solid $white;
            border-radius: 50%;
        }
    }

    &.show {
        .help-content {
            opacity: 1;
            pointer-events: initial;
            display: block;
        }
    }

    @include media-breakpoint-up(lg) {
        bottom: 5rem;
    }

    @include media-breakpoint-down(sm) {
        .btn-helper {
            padding: 0.25rem;
            right: 1rem;
            
            .fo {
                margin-right: 0;
            }
        }
    }
}