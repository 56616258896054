.border-main {
    border-bottom: 3px solid $primary;
}

.border-main-after {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        background-color: $primary;
    }
}

@include media-breakpoint-up(lg) {
    .border-lg-left {
        border-left: 1px solid $border-color;
    }

    .border-big-lg-left {
        border-left: 2px solid $border-color;
    }
}