.homepage {
    h2 {
        margin-bottom: rem(12) !important;
    }

    @include media-breakpoint-down(sm) {
        .homepage-jumbotron {
            background-image: none !important;

            &.homepage-jumbotron-padding {
                > .container {
                    padding-top: rem(36);
                    padding-bottom: rem(36);
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .homepage-jumbotron {
            background-repeat: no-repeat;
            background-position: top;
            background-size: cover;

            &.homepage-jumbotron-padding {
                > .container {
                    padding-top: rem(200);
                    padding-bottom: rem(36);
                }
            }
        }
    }
}
