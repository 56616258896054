.search-hit {
    .search-hit-head {
        display: flex;
        flex-direction: column;

        // > *:first-child {
        //     order: 2;
        // }

        // > *:last-child {
        //     order: 1;
        // }

        h2, .h2,
        h3, .h3,
        h4, .h4,
        h5, .h5,
        h6, .h6 {
            color: $primary;
            font-size: $font-size-lg;
            margin-bottom: 0 !important;
        }
    }
    .search-hit-breadcrumb {
        color: $gray-500;
        font-size: rem(12);
        margin-bottom: 0.5rem;
    }
    .search-hit-body {
        margin-top: 0.5rem;
    }

    & + .search-hit {
        margin-top: rem(40);
    }
}

.search-filters {
    background-color: $light;
    border: 1px solid $border-color;
    border-radius: $border-radius;

    @include media-breakpoint-down(xs) {
        border-radius: 0;
        margin-left: -15px;
        margin-right: -15px;
        border-left: 0;
        border-right: 0;
    }

    .search-filter-group {
        padding: 1rem;
    }

    .search-filter-input {
        input {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            background-color: $white;
            border: 1px solid $body-color;
            border-right: 0;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .input-group-append button, .input-group-text {

            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            background-color: $white;
            border: 1px solid $body-color;
            border-left: 0;
            padding-right: 1rem;
        }

        .input-group-text {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding: 0 1rem 0 0;
        }


    }

    hr {
        margin-top: 0;
        margin-bottom: 0;
    }

    h3 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        font-size: $font-size-base;

        [data-toggle="collapse"] {
            display: flex;
            align-items: center;
            color: inherit;
            text-decoration: inherit;
    
            &:after {
                margin-left: auto;
            }
        }
    }

    [data-toggle="collapse"] {
        position: relative;

        &:after {
            margin-left: 0.5rem;
        }

        &[aria-expanded] {
            &:after {
                @include faw-icon(\f078, 12);
            }
        }

        &[aria-expanded="true"] {
            &:after {
                @include faw-icon(\f077, 12);
            }
        }
    }

    .custom-control {
        font-family: $font-family-secondary;

        & + .custom-control {
            margin-top: rem(5);
        }
    }

    .custom-control-label::before {
        background-color: white;
    }

    .custom-checkbox .custom-control-label::before {
        border-radius: 0.2rem;
    }

    .badge {
        border: 1px solid $body-color;
        padding: 0.5em 1em;
        cursor: pointer;
        background-color: $white;
        font-weight: 500;
        margin-bottom: 0.5rem;
        margin-right: 0.3rem;
        max-width: 100%;
        white-space: normal;
        line-height: 1.2;

        &:hover, &.active {
            background-color: $body-color;
            color: $white;
        }
    }

    .date-range {
        .form-control {
            height: calc(1.5rem + 1rem + 2px);
            font-size: 0.8rem;
        }
    }
}