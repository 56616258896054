.jobs-page .main-content {
  $border-radius-lg: 24px;
  $border-radius: 8px;
  $border-radius-sm: 2px;

  @import "../jobs/accordion";
  @import "../jobs/advantages";
  @import "../jobs/badge";
  @import "../jobs/breadcrumb";
  @import "../jobs/buttons";
  @import "../jobs/card";
  @import "../jobs/form";
  @import "../jobs/heading";
  @import "../jobs/map";
  @import "../jobs/swiper";
  @import "../jobs/testimonials";
  @import "../jobs/toc";

  @include media-breakpoint-down(sm) {
    .content-body {
      padding-top: 0;
    }
  }

  .content-wysiwyg {
    h3, .h3 {
      font-size: 1rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    a:not(.btn):not(.card):not(.image-card) {
      color: $purple;

      &:hover {
        color: darken($purple, 10%);
      }
    }

    ul, ol {
      &:not(.pagination):not(.list-unstyled) {
        li {
          margin-bottom: 0.25rem;

          &:before {
            color: $body-color;
            font-size: 1.4rem;
            top: -0.4rem;
          }
        }
      }
    }
  }

  .rounded-xs {
    border-radius: $border-radius;
  }

  .link-jobs {
    background-color: transparent;
    border: 0;
    text-decoration: underline;
    color: $purple;

    &:hover {
      color: darken($purple, 10%);
    }
  }
}
