[class^="oji-"], [class*=" oji-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jobs-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$oji-work: \e900;
$oji-role: \e901;
$oji-diploma: \e902;
$oji-contract: \e903;
$oji-language: \e904;
$oji-finance: \e905;
$oji-balance: \e906;
$oji-bien-etre: \e907;
$oji-plus: \e908;
$oji-recompense: \e909;
$oji-process: \e90a;

.oji-work:before {
  content: fo-content($oji-work);
}
.oji-role:before {
  content: fo-content($oji-role);
}
.oji-diploma:before {
  content: fo-content($oji-diploma);
}
.oji-language:before {
  content: fo-content($oji-language);
}
.oji-contract:before {
  content: fo-content($oji-contract);
}
.oji-finance:before {
  content: fo-content($oji-finance);
}
.oji-balance:before {
  content: fo-content($oji-balance);
}
.oji-bien-etre:before {
  content: fo-content($oji-bien-etre);
}
.oji-plus:before {
  content: fo-content($oji-plus);
}
.oji-recompense:before {
  content: fo-content($oji-recompense);
}
.oji-process:before {
  content: fo-content($oji-process);
}
