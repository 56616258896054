.accordion {
  .accordion-title {
    padding: 0.7rem 3rem 0.7rem 1.625rem;
    font-size: rem(18);
  }

  .collapse:not(.show) + .accordion-title:before {
    left: -0.25rem;
    right: -0.25rem;
  }

  .accordion-collapse-inner {
    > *:last-child {
      margin-bottom: 0;
    }
  }
}
