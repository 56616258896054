.card {
  border-color: $purple-lighter;
  display: flex;
  text-decoration: none !important;
  border-radius: $border-radius-lg;

  &::before {
    content: none;
  }

  .card-title {
    display: flex;
    align-items: baseline;
    padding: 1.25rem;
    margin-bottom: 0 !important;
    font-size: 1rem;
    font-weight: 600;
    color: $body-color;
  }

  .card-body {
    padding: 0 1.25rem 1.25rem;
  }

  &:hover {
    background-color: $purple-light;
  }

  &.card-arrow {
    position: relative;
    padding-right: 2rem;

    &::after {
      @include faw-icon('\f054', 24);
      position: absolute;
      top: 50%;
      right: 1.1rem;
      transform: translateY(-50%);
      color: $purple;
    }
  }

  &.card-testimonial {
    padding-right: 4.5rem;
    display: grid;
    grid-template-columns: 10rem 1fr;

    > * {
      z-index: 2;
    }

    .card-title {
      padding-bottom: 0;
    }

    .card-img {
      width: 100%;
      padding: 1rem 0 1rem 1rem;
      grid-row: 1 / span 2;

      img {
        border-radius: $border-radius;
        aspect-ratio: 1;
      }
    }

    &.card-arrow {
      &::after {
        color: $body-color;
        right: 1.6rem;
      }
    }

    &:hover {
      background-image: $background-primary;
      border-color: #fff;

      &::before {
        content: '';
        background-color: #fff;
        position: absolute;
        top: 3px;
        left: 3px;
        right: 4rem;
        bottom: 3px;
        z-index: 1;
        background-image: none;
        opacity: 1;
        border-top-left-radius: rem(21);
        border-bottom-left-radius: rem(21);
      }

      &.card-arrow {
        &::after {
          color: #fff;
        }
      }
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: rem(106) 1fr;
      padding-right: 2.5rem;

      .card-img {
        grid-row: 1 / 1;
      }

      .card-title {
        align-self: start;
      }

      .card-body {
        grid-column: 1 / span 2;
      }

      &.card-arrow {
        &::after {
          right: 0.8rem;
        }
      }

      &:hover {
        &::before {
          right: 2.5rem;
        }
      }
    }
  }
}

.image-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $gray-900;
  border-radius: $border-radius;
  padding: rem(11);
  overflow: hidden;
  text-decoration: none !important;
  color: #fff;
  font-weight: 600;
  font-size: rem(24);
  text-align: center;
  line-height: 1.2;
  aspect-ratio: 1 / 1.1;
  justify-content: center;

  > * {
    z-index: 1;
  }

  &::before {
    content: '';
    background-color: rgba($gray-900, 0.56);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    aspect-ratio: 1 / 0.5;
    justify-content: space-evenly;
    padding: 1.5rem;
  }

  @include media-breakpoint-down(xs) {
    aspect-ratio: 1 / 0.7;
  }
}

.card-value {
  height: 100%;

  .card-title {
    align-items: center;
    gap: map-get($spacers, 2);

    img {
      width: rem(32);
      height: rem(32);
    }
  }
}
