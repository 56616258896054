.news {
    display: block;
    color: $body-color;

    .news-img {
        position: relative;
        padding-bottom: 51%;
        overflow: hidden;
        margin-bottom: 1rem;
    
        img {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: 2s ease;
        }
    }

    &:hover {
        text-decoration: none;
        cursor: pointer;

        .news-img {
            img {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }

        .news-title {
            color: $primary;
            text-decoration: underline;
        }

        .fleche-container .fo-fleche {
            margin-left: 0;
        }
    }
    
    @include media-breakpoint-up(md) {
        &.news-highlight {
            .news-title {
                font-size: $font-size-base * 1.5;
            }
            .news-img {
                margin-bottom: 1.25rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &.news-highlight {
            .news-title {
                font-size: $font-size-base * 2.125;
            }
        }
    }
}

.news-title {
    font-size: $font-size-lg;
    margin-bottom: 0.5rem !important;
}

.news-intro {
    color: $body-color !important;
}

.news-date {
    font-family: "Source Sans Pro";
}