.modal {
    padding-right: 0 !important;

    .modal-dialog {
        max-width: none;
        margin: 0;

        .modal-content {
            border-radius: 0;
            border: 0;

            .modal-body {
                padding: 3rem 1.5rem;
            }
        }
    }

    .close-icon {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        z-index: 1;
    }

    .input-group {
        max-width: 80%;
        width: 500px;
    }
}