.alert {
    margin-bottom: 0;
    
    &.alert-danger {
        background-color: $primary;
        color: $white;
        padding-bottom: 0;

        .close-icon span {
            background-color: $white;
        }
    }

    .close-icon {
        position: absolute;
        right: 1.5rem;
        top: .5rem;
        z-index: 1;
    }
}