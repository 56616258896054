.list-toc {
  position: sticky;
  top: calc(72px + 24px); //header collapsed + gap
  display: flex;
  flex-direction: column;
  gap: rem(10);
  padding-left: 0;
  list-style-type: none;

  a {
    display: inline-block;
    padding: 4px 8px;
    border-radius: $border-radius;
    text-decoration: none;
    color: $purple;
    font-weight: 400;
    transition: 0.3s ease;

    &:hover {
      background-color: $purple-light;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -15px;
    bottom: 0;
    width: 1px;
    background-color: $border-color;
  }
}
