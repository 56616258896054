@use "sass:math";

.print-only {
  display: none;
}

@media print {
  body {
    background-color: #fff;
    line-height: 1.2;
  }

  .container {
    width: auto;
    max-width: 100%;
    padding: 0;
  }

  @for $i from 1 through 12 {
    .col-print-#{$i} {
      width: #{percentage(math.div(round($i*8.33),100))};
      float: left;
    }
  }

  .page-footer, .page-footer-space {
    background: #ffffff;
  }

  .print-hide {
    display: none;
  }

  .print-only {
    display: block;
  }

  #wrapper {
    padding-top: 1rem !important;
  }

  .chapo {
    border-left: 10px $pink solid;
    padding-left: calc(2rem - 10px);
  }

  .collapse:not(.show) {
    display: block !important;
  }

  .accordion .accordion-title {
    padding: 0;
    margin-top: 2.5rem !important;
    margin-bottom: inherit !important;
  }

  .accordion .accordion-title a[aria-expanded]:after {
    content: none;
  }

  .accordion .accordion-collapse-inner {
    padding: 0;
  }
}