.badge {
  border-radius: $border-radius-sm;
  border: 1px solid $gray-500;
  background-color: #fff;
  color: $gray-500;
  font-size: 1rem;
  font-weight: 400;
  white-space: normal;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  hyphens: auto;
  word-break: break-word;
}
