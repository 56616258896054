.card {
    overflow: hidden;
    // safari fix: https://discourse.webflow.com/t/safari-not-hiding-overflow-on-rounded-corner-divs/55060
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    &:before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(to bottom, $primary, $pink);
        opacity: 0;
        transition: $transition-base;
    }

    > * {
        z-index: 1;
    }

    .card-header, .card-body {
        > *:last-child {
            margin-bottom: 0 !important;
        }
    }

    .card-header {
        border-bottom: 0;
    }

    &:not(.card-sm) {
        .card-header {
            position: relative;

            > * {
                z-index: 1;
            }

            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-image: linear-gradient(to bottom, $primary, $pink);
                opacity: 0;
                transition: $transition-base;
            }

            &:after {
                content: '';
                position: absolute;
                left: $card-spacer-x;
                right: $card-spacer-x;
                bottom: 0;
                height: 1px;
                background-color: $border-color;
            }
        }
    }

    .card-body {
        padding: $card-spacer-y $card-spacer-x;
        font-size: 1rem;
    }

    .card-footer {
        border-top: 0;
        padding-top: calc(#{$card-spacer-y} / 2);
    }

    .card-title {
        color: $primary;
        font-size: rem(20);

        &:not(:last-child) {
            margin-bottom: 1rem !important;
        }
    }

    &.card-sm {
        .card-body {
            padding: 0 $card-spacer-x;
        }
    }

    &:hover {
        &:not(.card-sm) {
            .card-header {
                color: $white;
                background-color: $primary;

                &:before {
                    opacity: 1;
                }

                .card-title {
                    color: $white;
                }

                &:after {
                    display: none;
                }
            }
        }

        &.card-sm {
            color: $white;
            background-color: $primary;

            &:before {
                opacity: 1;
            }

            .card-title {
                color: $white;
            }

            a {
                color: $white;
            }
        }

        .fleche-container .fo-fleche {
            margin-left: 0;
        }
    }
}
