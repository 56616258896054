.pagination {
    font-family: $font-family-secondary;

    &.pagination-sm {
        .page-link {
            padding: 0;
            line-height: rem(40);
            width: rem(40);
            height: rem(40);
            text-align: center;
        }

        .page-item {
            &:first-child, &:last-child {
                .page-link {
                    width: auto;
                    padding: 0 0.75rem;
                }
            }
        }
    }

    .page-item {
        &.active .page-link, .page-link:hover {
            background: $background-primary-down;
        }
    }
}