$circle-size: 500px;
$circle-thickness: 30px;

$circle-size-sm: 250px;
$circle-thickness-sm: 20px;

.circles {
    overflow: hidden;
    position: relative;

    * {
        z-index: 1;
    }

    &:before, &:after {
        content: '';
        display: block;
        width: $circle-size;
        height: $circle-size;
        border-radius: 50%;
        border-style: solid;
        border-color: $white;
        border-width: $circle-thickness;
        position: absolute;
    }

    &:before {
        top: -($circle-size/2 - 10);
        left: -($circle-size/2 - 50);
    }

    &:after {
        bottom: -($circle-size/2 - 50);
        right: -($circle-size/2 - 20);
    }

    &.circles-sm {
        &:before, &:after {
            width: $circle-size-sm;
            height: $circle-size-sm;
            border-width: $circle-thickness-sm;
        }
    
        &:before {
            top: -($circle-size-sm/2 - 10);
            left: -($circle-size-sm/2 - 30);
        }
    
        &:after {
            bottom: -($circle-size-sm/2 - 30);
            right: -($circle-size-sm/2 - 20);
        }
    }

    &.circles-error {
        &:before {
            top: -($circle-size/2 - 10);
            right: -($circle-size/2 - 100);
            left: auto;
        }

        &:after {
            bottom: -($circle-size/1.5 - 50);
            left: -($circle-size/2 - 50);
            right: auto;
            border-color: $gray-100;

            @include media-breakpoint-down(md) {
                content: none;
            }
        }
    }
}