#footer {
    a {
        color: $body-color;
    }

    .footer-list-header {
        // height: 62px; // 2 lines of text
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    hr {
        border-top: 2px solid $border-color;
    }
}