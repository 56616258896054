#stats-swiper {
  @include media-breakpoint-down(md) {
    .swiper-slide {
      width: auto;
    }
  }

  @include media-breakpoint-down(md) {
    &.swiper-container {
      &:before,
      &:after {
        content: '';
        transition: $transition-base;
        height: 100%;
        width: 25px;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 10;
        opacity: 0;
        pointer-events: none;

        @include media-breakpoint-up(md) {
          width: 150px;
        }
      }

      &:before {
        background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
        left: 0;
      }

      &:after {
        background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
        right: 0;
      }

      &.mask-beginning:before {
        opacity: 1;
      }

      &.mask-end:after {
        opacity: 1;
      }
    }
  }
}

.swiper-custom-navigation {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 60%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    background-color: $gray-400;
    z-index: 0;
  }

  .btn.disabled {
    opacity: 1;
    background: none;
    background-color: $gray-400;
    border-color: $gray-400;

    &.btn-round {
      width: 36px;
      height: 36px;
      line-height: 36px;
      margin-left: calc(1rem + 2px);
      margin-right: calc(1rem + 2px);
    }
  }

  .swiper-next {
    padding: 0 0 0 0.1rem;
  }

  .swiper-prev {
    padding: 0 0.1rem 0 0;
  }

  .swiper-next,
  .swiper-prev {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
