$heading-illustration-min-height: 270px;

.heading {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;

  .heading-content {
    position: relative;
    display: flex;
    flex-direction: column;
    order: 2;
    gap: 1rem;
    color: #fff;
    padding: rem(24);
    background-color: $gray-500;

    h1 {
      font-size: rem(28);
      margin-bottom: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: -$heading-illustration-min-height;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(0deg, $pink 45%, transparent)
    }

    > * {
      position: relative;
      z-index: 1;
    }
  }

  .heading-illustration {
    background-color: $primary;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: $heading-illustration-min-height;
    order: 1;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    border-radius: $border-radius;
    margin-left: 0;
    margin-right: 0;

    .heading-content,
    .heading-illustration {
      flex: 1 0 50%;
    }

    .heading-content {
      order: initial;
      padding: rem(40);

      &::before {
        right: -100%;
        bottom: 0;
        background-image: linear-gradient(90deg, $pink 50%, transparent 75%)
      }
    }

    .heading-illustration {
      min-height: auto;
      order: initial;
    }
  }
}

.backlink + h2,
.backlink + .h2 {
  margin-top: 0;
}
