.map {
  position: relative;

  svg {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .region {
    path {
      fill: $purple-map;
    }

    circle {
      fill: url(#text_gradient);
    }

    text {
      font-size: rem(25.61);
      font-weight: 600;
      fill: #fff;
    }

    &[role="button"]:hover {
      path {
        fill: url(#region_gradient); // gradient defined in SVG
      }

      circle {
        fill: #fff;
      }

      text {
        fill: $primary;
      }
    }
  }

  .job-popover {
    position: absolute;
    border: 1px solid $border-color;
    border-radius: $border-radius-lg;
    padding: 1.5rem;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 #00000052;
    min-width: 330px;

    .job-popover-content {
      display: flex;
      flex-direction: column;
      gap: map-get($spacers, 4);
    }

    .job-popover-title {
      font-size: rem(24);
      font-weight: 700;
      color: $primary;
      margin-bottom: 0 !important;
      padding-right: 3rem;
    }

    ul {
      a {
        color: $purple;
      }

      &[role="tablist"] {
        button {
          border: 0;
          color: $purple;
          background-color: transparent;

          &:not(.active) {
            text-decoration: underline;
          }
        }
      }
    }

    .btn-close {
      position: absolute;
      top: 1rem;
      right: 1rem;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($purple, 0.25);
      }
    }
  }
}
