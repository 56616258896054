.styled-list {
  padding-left: 0;
  list-style: none;

  li {
    font-weight: 600;
    margin-bottom: rem(24);
    position: relative;
    padding-left: 2.5rem;
    overflow: hidden;

    &:before {
      color: $primary;
      margin-right: 1rem;
      @include font-onem-icon($fo-fleche_hover, 11);
      position: absolute;
      left: -3px;
      top: 0.3rem;
      transition: $transition-base;
    }

    a {
      color: $body-color;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      &:before {
        left: 0;
      }
    }
  }
}

.list-group {
  .list-group-title {
    padding: rem(24);
    text-decoration: none;
    position: relative;

    > * {
      margin-bottom: 0;
      font-size: rem(18) !important;
      text-transform: uppercase;
    }


    &[aria-expanded] {
      &:after {
        @include faw-icon(\f078);
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
      }
    }

    &[aria-expanded="true"] {
      &:after {
        @include faw-icon(\f077);
      }
    }

    &[aria-expanded="false"] {
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      transition: 0.1s ease 0.3s;
    }
  }

  .list-group-item-action {
    font-family: $font-family-secondary;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  .list-group-item:last-child {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &.sticky {
    top: 80px;
  }
}

body:not(.jobs-page) {
  $list-toc-padding-left: 1.25rem;

  .list-toc-title {
    text-transform: uppercase;
    color: $primary;
    font-size: $font-size-lg;
    padding-left: $list-toc-padding-left;
    margin-bottom: rem(24) !important;
  }

  .list-toc {
    list-style-type: none;
    position: relative;
    padding-left: $list-toc-padding-left;
    line-height: 1.2;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      bottom: 2px;
      width: 2px;
      background-color: $border-color;
    }

    li {
      margin-bottom: rem(16);

      &:last-child {
        margin-bottom: 0;
      }

      &.divider {
        padding-top: rem(16);
        border-top: 2px solid $border-color;
      }
    }

    a {
      color: $body-color;
      font-weight: $font-weight-semi;
      position: relative;
      transition: 0.3s ease;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -$list-toc-padding-left;
        bottom: 0;
        width: 2px;
        background-color: transparent;
        transition: 0.3s ease;
      }

      &:hover {
        text-decoration: none;
        color: $primary;

        &:before {
          background-color: $primary;
        }
      }
    }
  }
}

.list-check, .list-times, .list-check-green {
  padding-left: 1.5rem;
  list-style-type: none;
  font-family: $font-family-secondary;

  li {
    position: relative;

    &:before {
      position: absolute;
      top: 4px;
      left: -1.5rem;
    }

    & + li {
      margin-top: rem(10);
    }
  }
}

.list-check {
  li {
    &:before {
      @include faw-icon(\f00c);
      color: $primary;
      font-size: 0.8rem;
    }
  }
}

.list-check-green {
  li {
    &:before {
      @include faw-icon(\f00c);
      color: $green;
      font-size: 0.8rem;
    }
  }
}

.list-times {
  li {
    &:before {
      @include faw-icon(\f00d);
      color: $red;
    }
  }
}
